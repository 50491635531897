<template>
    <v-container>
        <v-row>
            <v-breadcrumbs large :items="items_nav">
                <template v-slot:divider>
                <v-icon>mdi-chevron-right</v-icon>
                </template>
                <template v-slot:item="{ item }">
                <v-breadcrumbs-item :disabled="item.disabled">
                    {{ item.text }}
                </v-breadcrumbs-item>
                </template>
            </v-breadcrumbs>
        </v-row>
        <v-card elevation="4">
            <v-card-title>
                <v-row>
                    <div class="col">
                        Nova Inspiração
                    </div>
                </v-row>
            </v-card-title>
            <v-card-text>
                <v-form ref="form" lazy-validation>
                  <!-- <v-row>
                    <v-col cols="12"  md="4" >
                      <v-card elevation="4" height="160" width="160">
                        <v-container grid-list-sm fluid>
                          <v-layout row wrap>
                            <v-img
                              aspect-ratio="1"
                            >              
                            <template v-slot:placeholder>
                              <v-row
                                class="fill-height ma-0"
                                align="center"
                                justify="center"
                              >
                                <v-progress-circular
                                  indeterminate
                                  color="white lighten-5"
                                ></v-progress-circular>
                              </v-row>
                            </template>        
                            </v-img>
                          </v-layout>
                        </v-container>
                      </v-card>
                    </v-col>
                  </v-row> -->
                    <v-row>
                        <v-col cols="12"  md="6" class="text-center">
                            <v-text-field
                                v-model="inspiracao.titulo"
                                counter="40"
                                maxlength="40"
                                label="Título"
                                required
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="6">
                          <v-select
                            v-model="inspiracao.categoria_id"
                            item-text="nome"
                            item-value="id"
                            :items="getCategorias"
                            label="Categoria"
                            
                          >
                          </v-select>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="12"  md="8" class="text-center">
                            <v-text-field
                                v-model="inspiracao.mensagem"
                                counter="80"
                                maxlength="80"
                                label="Mensagem"
                                required
                            ></v-text-field>
                        </v-col>

                        <v-col cols="12"  md="4" class="text-center">
                            <v-text-field
                                v-model="inspiracao.tags"
                                counter="60"
                                maxlength="60"
                                label="Tags"
                                required
                            ></v-text-field>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="12"  md="12" class="text-center">
                            <v-textarea
                                v-model="inspiracao.conteudo"
                                label="Conteúdo"></v-textarea>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="12"  md="12" class="text-center">
                            <v-text-field
                                v-model="inspiracao.telefone"
                                counter="16"
                                maxlength="16"
                                label="Telefone"
                                required
                            ></v-text-field>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="12"  md="12">
                            <v-file-input
                                v-model="imagem_upload"
                                :rules="rulesImagem"
                                accept="image/png, image/jpeg"
                                placeholder="Adicionar imagem"
                                prepend-icon="mdi-camera"
                                append-icon
                                show-size
                                @change="uploadImagem()"
                            ></v-file-input>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col
                            v-for="url in inspiracao.banners"
                            :key="url"
                            class="d-flex child-flex"
                            cols="4"
                        >
                        <v-hover>
                            <template v-slot:default="{ hover }">
                                <v-img
                                :src="url"
                                aspect-ratio="1"
                                class="grey lighten-2"
                            >
                                <template v-slot:placeholder>
                                <v-row
                                    class="fill-height ma-0"
                                    align="center"
                                    justify="center"
                                >
                                    <v-progress-circular
                                    indeterminate
                                    color="grey lighten-5"
                                    ></v-progress-circular>
                                </v-row>

                                </template>
                                <v-fade-transition>
                                <v-overlay
                                    v-if="hover"
                                    absolute
                                    color="#FF6358"
                                >
                                    <v-btn icon @click="deleteImagem(url)"><v-icon x-large>mdi-delete</v-icon></v-btn>
                                </v-overlay>
                            </v-fade-transition>
                                
                            </v-img>
                            
                            </template>
                            
                        </v-hover>
                        </v-col>
                    </v-row>
                </v-form>
            </v-card-text>
            <v-card-action class="d-flex flex-row-reverse">
                <div class="ma-4">
                        <v-btn
                            color="success"
                            dark
                            @click="salvar()"
                            >
                            SALVAR
                        </v-btn>
                    </div>
            </v-card-action>
        </v-card>

        <v-dialog v-model="dialogProgress" hide-overlay persistent width="300">
          <v-card color="primary" dark>
            <v-card-text>
              Realizando operação por favor aguarde...
              <v-progress-linear
                indeterminate
                color="white"
                class="mb-0"
              ></v-progress-linear>
            </v-card-text>
          </v-card>
        </v-dialog>

        <v-dialog v-model="dialogMessage" width="480">
          <v-card class="mx-auto" dark>
            <v-card-title>
                <span class="title font-weight-light">{{ message.title }}</span>
            </v-card-title>
            <v-card-text class="headline font-weight-bold">
                {{ message.body }}
            </v-card-text>
            </v-card>
        </v-dialog>


    </v-container>
</template>

<script>
import database from "@/services/database";
import { mapGetters } from "vuex";
import { v4 as uuidv4 } from 'uuid';

export default {
    data() {
        return {
            items_nav: [
                {
                    text: "Sistema",
                    disabled: false,
                },
                {
                    text: "Inspiracoes",
                    disabled: true,
                },
                {
                    text: "Novo",
                    disabled: true,
                }
            ],
            rulesImagem: [
                value => !value || value.size < 2000000 || 'Tamanho da imagem deve ser inferior a 2 MB!',
            ],
            imagem_upload: null,
            inspiracao_id: "",
            inspiracao: {
                titulo: "",
                mensagem: "",
                conteudo: "",
                tags: "",
                telefone: "",
                categoria_id: "",
                banners: [],
                cover_url: ""
            },
            loading: false,
            dialogProgress: false,
            message: {
              title: "",
              body: ""
            },
            dialogMessage: false,
      }
    },
    computed: {
        ...mapGetters(["getCategorias"]),
    },
    mounted() {
        this.getDataFromApi();
    },
    methods: {
      getDataFromApi: async function() {
        this.inspiracao_id = uuidv4();
      },
      salvar: async function() {
        this.dialogProgress = true;
        var result;
        result = await database.addInspiracao(this.inspiracao_id, this.inspiracao);
        this.dialogProgress = false;
        if(result) {
          this.dialogProgress = false;
          this.message.title = "Operação realizada";
          this.message.body = "Alteração realizada com sucesso!";
          this.dialogMessage = true;
        } else {
          this.dialogProgress = false;
            this.message.title = "Operação falhou";
            this.message.body = "Verifique sua conexão e tente novamente!";
            this.dialogMessage = true;
        }

      },
      saveImagem: async function(image) {
        var result = await database.uploadImagemInspiracao(
            image,
            this.imagem_upload.name.split(".")[1],
            this.inspiracao_id,
            this.imagem_upload.name.split(".")[0]
        );
        this.inspiracao.banners.push(result.url);
        this.imagem_upload = null;
        this.dialogProgress = false;
      },
      uploadImagem: async function() {
        const reader = new FileReader();
        this.dialogProgress = true;
        var image = null;
        reader.readAsDataURL(this.imagem_upload);
        if(this.imagem_upload == null) {
            return;
        }
        reader.onload = (e) => {
            image = e.target.result;
        };
        reader.onloadend = async () => await this.saveImagem(image);
        
        },
        deleteImagem: function(item) {
          for( var i = 0; i < this.inspiracao.banners.length; i++){ 
            if ( this.inspiracao.banners[i] === item) { 
                this.inspiracao.banners.splice(i, 1); 
            }
        }
      },
    },
}
</script>

<style>

</style>